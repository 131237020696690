<template>
  <div>
    <v-autocomplete
      :prepend-icon="icon ? icons.mdiAlphaCCircle : null"
      :clearable="clearable"
      hide-details="auto"
      :disabled="disabled"
      v-model="select"
      :items="lista"
      label="Caja"
      item-value="id"
      item-text="descripcion"
      :dense="outlined"
      :outlined="outlined"
      :loading="cargando"
      :rules="rules"
    >
      <template slot="item" slot-scope="data">
        {{ data.item.descripcion }}
      </template>
    </v-autocomplete>
 
  </div>
</template>
<script>
import CajaServices from '@/api/servicios/CajaServices'
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import { mdiAlphaCCircle, mdiPlusCircleOutline } from '@mdi/js'

export default {
  props: {
    value: String,
    disabled: Boolean,
    dense: Boolean,
    outlined: Boolean,
    icon: Boolean,
    todos: Boolean,
    clearable: Boolean,
    rules: Array,
    padre:{
        type:Number,
        default:-1
    }
  },
  watch:{
    padre : function(news,olds){
      if (news != olds){
        this.cargarDatos()
      }
    }
  },
  setup(props, contex) {
    const lista = ref([])
    const select = ref(null)
    const cargando = ref(false)

    watch(props, () => {
      select.value = props.value
    })
    onBeforeMount(() => {
      cargarDatos()
      if (props.value != undefined) {
        select.value = props.value
      }
    })

    const selectId = id => {
      select.value = id
    }

    const cargarDatos = () => {
    
      lista.value = []
      cargando.value = true
      try {
        CajaServices.Consultar({
          idSucursal: props.padre,
          indActivo: true,
        })
          .then(response => {
            console.log(response)
            if (response.data.estatus == true) {
              lista.value = response.data.datos
            }
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            cargando.value = false
          })
      } catch (error) {
        //alert(error)
        cargando.value = false
      }
    }

    watch(select, () => {
      contex.emit('input', select.value)
    })
    return {
      icons: {
        mdiAlphaCCircle,
        mdiPlusCircleOutline,
      },
      lista,
      select,
      cargando,
      selectId,
      cargarDatos
    }
  },
}
</script>