<template>
    <div>

        <v-autocomplete :prepend-icon="icon ? icons.mdiAlphaCCircle : null" :clearable="clearable" hide-details="auto"
            :disabled="disabled" v-model="select" :items="lista" label="Estatus" item-value="id" item-text="descripcion"
            :dense="outlined" :outlined="outlined" :loading="cargando" :rules="rules">

        </v-autocomplete>
    </div>

</template>
<script>
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import { mdiAlphaCCircle, mdiPlusCircleOutline } from '@mdi/js'  
import EstatusServices from  '@/api/servicios/EstatusServices'
export default {

    props: {
        value: String,
        disabled: Boolean,
        dense: Boolean,
        outlined: Boolean,
        icon: Boolean,
        todos: Boolean,
        default: Number,
        clearable: Boolean,
        padre: Number,
        rules: Array
    },

    watch: {
        padre: function (newVal, oldVal) {

            if (newVal != oldVal) {
                this.cargarDatos()
            }
        },
        value: function (newVal, oldVal) {
            this.select = newVal
        }
    },
    setup(props, contex) {
        const lista = ref([])
        const select = ref(null)
        const cargando = ref(false)


        onBeforeMount(() => {
            cargarDatos()
            if (props.default != undefined) {
                select.value = props.default
            }
        })

        const selectId = (id) => {

            select.value = id
        }
        const cargarDatos = () => {
            lista.value = []
            cargando.value = true
            try {
                
                EstatusServices.Consultar({
                    id: -1,
                    IdGrupoEstatus: props.padre == null ? 0 : props.padre
                }).then(response => {
                    console.log(response)
                    if (response.data.estatus == true) {
                        lista.value = response.data.datos
                    }
                })
                    .catch(error => {
                        console.log(error)
                    })
                    .finally(() => {
                        cargando.value = false
                    })
            } catch (error) {

                cargando.value = false
            }
        }
        watch(select, () => {

            contex.emit('input',
                select.value
            )
        })

        return {
            icons: {
                mdiAlphaCCircle,
                mdiPlusCircleOutline
            },
            lista,
            select,
            cargando,
            selectId,
            cargarDatos 
        }
    },
}
</script>