<template>
  <div>
    <v-card flat class="pa-3 mt-2">
      <v-card-title> Reporte de facturas </v-card-title>
      <v-card-text class="flex">
        <v-row>
          <v-col cols="4">
            <SelectMulti :outlined="true" :clearable="true" v-model="sucursalesSelect"></SelectMulti>
          </v-col>
          <v-col cols="4">
            <dos-fechas
              :fechas="[filtros.fechaDesde, filtros.fechaHasta]"
              @getFechas="getFechas"
              :icon="false"
            ></dos-fechas>
          </v-col>
          <v-col cols="4">
            <SelectCaja
              :clearable="true"
              :dense="true"
              :outlined="true"
              :padre="store.state.sucursalSelect.id"
              v-model="filtros.idCaja"
            />
          </v-col>
          <v-col cols="4">
            <SelectEstatus :clearable="true" :dense="true" :outlined="true" :padre="3" v-model="filtros.idEstatus" />
          </v-col>
          <v-col cols="12" class="text-right">
            <v-btn
              class="mx-1 my-1"
              small
              :loading="cargandoDatos"
              :disabled="cargandoDatos"
              color="secondary"
              @click="limpiar()"
            >
              Limpiar
            </v-btn>
            <v-btn
              class="mx-1 my-1"
              :loading="cargandoDatos"
              small
              :disabled="cargandoDatos"
              color="primary"
              @click="consultar()"
            >
              Buscar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text>
        <TablaReporte
          @GetVer="getVer"
          :acciones="['VER' ]"
          :nombreReporte="nombreReporte"
          :cabeceraIn="cabecera"
          :datosIn="datos"
          :exportar="['EXCEL', 'PDF']"
          :cargando="cargandoDatos"
        ></TablaReporte>
      </v-card-text>
      <!-- Table -->
    </v-card>
    <ModalViewFactura ref="ModalViewFacturaRef" 
          @GetAnular="GetAnular"/>
  </div>
</template>
<script>
import { onBeforeMount, ref, context, watch } from '@vue/composition-api'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiStoreEdit,
  mdiPencilOutline,
  mdiAccountCancel,
  mdiAccountEdit,
  mdiAccountCheck,
  mdiCheck,
  mdiCancel,
  mdiStoreEditOutline,
  mdiPencil,
  mdiCached,
  mdiEyeOutline,
} from '@mdi/js'
import config from '@/api/config'
import store from '@/store'
import FacturaServices from '@/api/servicios/FacturaServices'
import TablaReporte from '@/components/TablaReporte.vue'
import SelectCaja from '../../maestro/caja/componentes/Select.vue'
import DosFechas from '@/components/DosFechas.vue'
import SelectMulti from '@/views/sistema/configuracion/sucursal/componentes/SelectMulti.vue'
import moment from 'moment'
import SelectEstatus from '../../../maestro/estatus/componentes/Select.vue'
import ModalViewFactura from '../../facturacion/componentes/ModalView.vue'
import FuncionesGenerales from '@/funciones/funciones'
import { acciones } from '@/modulos'
import Vue from 'vue'
export default {
  components: {
    TablaReporte,
    DosFechas,
    SelectCaja,
    SelectEstatus,
    ModalViewFactura,
    SelectMulti,
  },
  setup(props, context) {
    const search = ref('')
    const datos = ref([])
    const cargandoDatos = ref(false)
    const ModalViewFacturaRef = ref(null)
    const sucursalesSelect = ref([])
    const filtros = ref({
      fechaDesde: moment().format('YYYY-MM-DD'),
      fechaHasta: moment().format('YYYY-MM-DD'),
      id: -1,
      idCaja: -1,
      idSucursal: '',
      idArea: -1,
      idEstatus: -1,
      idCliente: -1,
      idVendedor: -1,
      idPedido: -1,
      numeroFactura: -1,
      numeroControl: -1,
      numeroDocumento: '',
    })
    const anulando = ref(false)
    const GetAnular = async item => {
    
      let Hash = 0
      if (!FuncionesGenerales.validarAcciones(acciones.FACTURA_ANULAR, store.state.sucursalSelect.id)) {
        Hash = await FuncionesGenerales.HashsConsultar()
      } else {
        Hash = 3
      }

      if (Hash == 3) {
        if (item.idEstatus != 13) {
          anulando.value = true
          Vue.swal({
            icon: 'warning',
            html: '<h3>¿Seguro que quiere anular la factura? </h3>' + `<p>${item.numeroDocumento}</p>`,
            showCancelButton: true,
            confirmButtonText: 'Si, Seguro',
            showLoaderOnConfirm: true,
            cancelButtonText: 'No',
            preConfirm: () => {
              return FacturaServices.Anular(store.state.user.idUsuario, { id: item.id })
                .then(response => {
                  if (response.data.estatus == true) {
                    Vue.swal({
                      icon: 'success',
                      title: `Exito`,
                      text: response.data.mensaje,
                    })
                    consultar()
                  } else {
                    Vue.swal({
                      icon: 'error',
                      title: `Oops...`,
                      text: `${response.data.mensaje}: ${response.data.datos.mensajeError}`,
                    })
                  }
                  return true
                })
                .catch(error => {
                  Vue.swal({
                    icon: 'error',
                    title: `Oops...`,
                    text: `hubo un error: ${error}`,
                  })
                  return false
                })
            },
            allowOutsideClick: () => !Swal.isLoading(),
          }).then(result => {
            //elimiandoPago.value = false
          })
        }
      } else {
        Vue.swal({
          icon: 'error',
          text: `No tienes permiso para realizar esta accion`,
        })
      }
    }

    const getVer = dato => {
      console.log('getVer', dato)
      ModalViewFacturaRef.value.abrir(dato.id)
    }
    const getFechas = datos => {
      console.log(datos)
      filtros.value.fechaDesde = datos.desde
      filtros.value.fechaHasta = datos.hasta
    }
    const nombreReporte = ref(`Reporte de facturas-${filtros.value.fechaDesde} al ${filtros.value.fechaHasta}`)
    const cabecera = ref([
      { text: 'Id', value: 'id', visible: true },
      { text: 'Sucursal', value: 'area.sucursal.nombre', visible: true },
      { text: 'Fecha', value: 'fecha', format: { date: true }, visible: true },
      { text: 'Caja', value: 'caja.descripcion', visible: true },
      { text: 'Número factura', value: 'numeroFactura', visible: true },
      { text: 'Número documento', value: 'numeroDocumento', visible: true },
      { text: 'Nombre cliente', value: 'cliente.nombre', visible: true },
      { text: 'Documento cliente', value: 'cliente.documento', visible: true },
      {
        text: 'Estatus',
        value: 'estatus.descripcion',
        format: { size: 'small', chip: true, color: ['primary', 'error'], valores: ['Activa', 'Eliminada'] },
        visible: true,
      },
      { text: 'SubTotal', value: 'montoSubTotal', visible: false, align: 'right' },
      { text: 'IVA', value: 'montoIva' },
      { text: 'Descuento', value: 'montoDescuento', visible: false, align: 'right' },
      { text: 'IGTF', value: 'montoIgtf', visible: false, align: 'right' },
      { text: 'Total', value: 'montoTotal', visible: true, align: 'right' },
      { text: 'Accion', value: 'accion', visible: true },
    ])

    const consultar = () => {
      try {
        cargandoDatos.value = true
        FacturaServices.Consultar({
          ...filtros.value,
          idSucursal: sucursalesSelect.value.join(','),
        })
          .then(response => {
            console.log(response)
            if (response.data.estatus == true) {
              datos.value = response.data.datos
            }
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            cargandoDatos.value = false
          })
      } catch (error) {
        //alert(error)
        cargandoDatos.value = false
      }
    }
    const limpiar = () => {
      datos.value = []
      filtros.value = {}
      sucursalesSelect.value = []
    }
    return {
      consultar,
      getVer,
      getFechas,
      limpiar,
      store,
      search,
      datos,
      filtros,
      cargandoDatos,
      nombreReporte,
      cabecera,
      ModalViewFacturaRef,
      sucursalesSelect,
      GetAnular,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiAccountCancel,
        mdiAccountEdit,
        mdiAccountCheck,
        mdiCheck,
        mdiCancel,
        mdiStoreEdit,
        mdiStoreEditOutline,
        mdiPencil,
        mdiCached,
        mdiEyeOutline,
      },
    }
  },
}
</script>