<template>
  <v-card>
    <v-card-title>
      <span>Factura</span>
      <v-spacer></v-spacer>
       
    </v-card-title>
    <v-divider></v-divider>
   
    <v-card-text v-if="!cargando && datos">
      <v-row>
        <v-col lg="8" md="8" sm="12" cols="12">
          <CabeceraEmpresa></CabeceraEmpresa>
        </v-col>
        <v-col lg="4" md="4" sm="12" cols="12">
          <h2 class="pt-12 pb-2">
            #
            <small>{{ datos.numeroDocumento }} </small>
          </h2>
          <strong class="m-0 p-0"> Creacion </strong>
          <br />
          <span class="m-0 p-0">Fecha: {{ FuncionesGenerales.formatoFecha(datos.fechaCreacion, 1) }} </span>
          <br />
          <span class="m-0 p-0">Hora: {{ FuncionesGenerales.formatoFecha(datos.fechaCreacion, 2) }} </span>
 
        </v-col>
      </v-row>
    
      <v-divider></v-divider>
      <v-row class="mt-4 mb-2">
        <v-col lg="8" md="8" sm="12" cols="12">
          <strong class="m-0 p-0"> Cliente: </strong>
          <br />
          <span>Documento: {{ datos.cliente.documento }} </span> <br />
          <span>Nombre: {{ datos.cliente.nombre }} {{ datos.cliente.apellido }}</span> <br />

          <h4 class="m-0 p-0">Estatus: {{ datos.estatus.descripcion }}</h4>
        </v-col>
        <v-col lg="4" md="4" sm="12" cols="12"> 
          <strong class="m-0 p-0"> Vencimiento: </strong> 
        </v-col>
      </v-row>
      <v-divider></v-divider>
    <v-row>
        <v-col cols="12">
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Producto</th>
                  <th class="text-right">Cant</th>
                  <th class="text-right">Precio</th>
                  <th class="text-right">Descuento</th>
                  <th class="text-right">Iva</th>
                  <th class="text-right">Total</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, i) in datos.detalleFactura" :key="i">
              
                  <td>
                    <small> {{ item.productoSucursal.producto.barra }} </small>
                    <br />
                    {{ item.productoSucursal.producto.nombre }}
                  </td>

                  <td class="text-right">
                    {{ item.numeroUnidades }}
                  </td>
                  <td class="text-right">
                    {{ FuncionesGenerales.formatoNumeric(item.pvp) }}
                  </td>
                  <td class="text-right">
                    {{ FuncionesGenerales.formatoNumeric(0) }}
                  </td>
                  <td class="text-right">
                    {{ FuncionesGenerales.formatoNumeric(item.iva) }}
                  </td>
                  <td class="text-right">
                    {{ FuncionesGenerales.formatoNumeric(item.total) }}
                  </td>
                
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
     <v-col cols="12" sm="6" md="8" lg="8"> 
      <h5 class="ml-4">Pagos</h5>
      <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                <th>Moneda</th>
                <th>FormaPago</th>
                <th>Monto Moneda</th>
                <th>Tasa</th>
                <th>Monto {{ FuncionesGenerales.monedaPrincipal().simbolo }}</th>
              </tr>
              </thead>
              <tbody >
                <tr v-for="(item,index) in datos.pagoFactura"  :key="index">
                <td>{{ item.formaPago.moneda.nombre }}</td>
                <td>{{ item.formaPago.descripcion }}
                  <span v-if="item.montoPagoMoneda  < 0">
                    <br>
                  <small>vuelto</small>  
                  </span>
                </td>
                <td>{{item.formaPago.moneda.simbolo}} {{ item.montoPagoMoneda }}</td>
                <td>{{ FuncionesGenerales.formatoNumeric(item.formaPago.moneda.indMultiplicar ? 1/ item.tasa: item.tasa)  }}</td>
                <td>{{ FuncionesGenerales.monedaPrincipal().simbolo }} {{ item.montoPagoReal }}</td> 
              </tr>
              </tbody>
            </template>
      </v-simple-table>

     </v-col>
        <v-col cols="12" sm="6" md="4" lg="4">
          <h5 class="ml-4">Totales</h5>
          <v-simple-table dense>
            <template>
              <tbody>
                <tr>
                  <td>Sub Total</td>
                  <td class="text-right">
                    <span> {{ FuncionesGenerales.monedaPrincipal().simbolo }}</span>
                    {{ FuncionesGenerales.formatoNumeric(datos.montoSubTotal, 2) }}
                  </td>
                </tr>
                <tr>
                  <td>Descuento</td>
                  <td class="text-right">
                    <span> {{ FuncionesGenerales.monedaPrincipal().simbolo }}</span>
                    {{ FuncionesGenerales.formatoNumeric(datos.montoDescuento, 2) }}
                  </td>
                </tr>
                <tr>
                  <td>Impuesto</td>
                  <td class="text-right">
                    <span> {{ FuncionesGenerales.monedaPrincipal().simbolo }}</span>
                    {{ FuncionesGenerales.formatoNumeric(datos.montoIva, 2) }}
                  </td>
                </tr>
              
                <tr>
                  <td>
                    <h4><span class="primary--text">Total</span></h4>
                  </td>
                  <td class="text-right">
                    <h4>
                      <span class="primary--text">
                        <span> {{ FuncionesGenerales.monedaPrincipal().simbolo }}</span>
                        {{ FuncionesGenerales.formatoNumeric(datos.montoTotal, 2) }}
                      </span>
                    </h4>
                  </td>
                </tr>  
              </tbody>
            </template>
          </v-simple-table>
        </v-col>  
      </v-row>

      <v-divider></v-divider>
    </v-card-text>
    <v-card-actions>
      <v-row>
        <v-col cols="12" class="text-center mt-5">
           <BtnPdf :id="id" :tipoBtn="1"></BtnPdf> 
          <v-btn
            v-if="VerButton == true"
            color="error"
            class="ml-2"
            small
            :disabled="datos.idEstatus == 9 "
            @click="GetAnular(datos)"
          >
            <v-icon left small>
              {{ icons.mdiBlockHelper }} </v-icon
            >Anular
          </v-btn>
          <v-btn
            v-if="VerButton == true"
            color="secondary"
            class="ml-2"
            small
            :disabled="datos.idEstatus == 9 "
            @click="GetImprimir(datos)"
          >
            <v-icon left small>
              {{ icons.mdiPrinterPos }} </v-icon
            >Imprimir Tiket
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions> 
  </v-card>
</template>
<script>
import CabeceraEmpresa from '@/components/CabeceraEmpresa.vue'
import FuncionesGenerales from '@/funciones/funciones'
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import BtnPdf from './BtnPdf.vue'
import store from '@/store'
import { mdiClose, mdiPrinter, mdiPencil, mdiCheck, mdiBlockHelper, mdiFilePdfBox,mdiPrinterPos } from '@mdi/js' 
import ImprimirTermicaServices from '@/api/worker/ImprimirTermicaServices'
import FacturaServices from '@/api/servicios/FacturaServices'
export default {
  components: {
    CabeceraEmpresa,
   BtnPdf,
  },
  props: {
    id: Number,
    modal: Boolean,
    VerButton: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, context) {
    const cargando = ref(false)
    const datos = ref({})
    onBeforeMount(() => {
      cargar()
    })
    watch(props, () => {
      cargar()
    })

    const cerrarModal = () => {
      context.emit('GetCerrarModal')
    }
    const cargar = () => {
     
      cargando.value = true
      FacturaServices.ConsultarDetalle({ id: props.id })
        .then(response => {
          if ((response.data.estatus = true)) {
            if (response.data.datos != null && response.data.datos.length > 0) { 
              datos.value = response.data.datos[0]
            } else {
              store.commit('setAlert', {
                message: 'No hay resultado en la busqueda',
                type: 'warning',
              })
            }
          } else {
            store.commit('setAlert', {
              message: response.data.mensaje,
              type: 'warning',
            })
          }
        })
        .catch(err => {
          store.commit('setAlert', {
            message: err,
            type: 'error',
          })
        })
        .finally(() => {
          cargando.value = false
        })
    }


    const Imprimir = (datos) => {
      console.log('Imprimir', datos)
     // console.log('Imprimir2', datosFactura)
      const datosImprimir = [
        {
          texto: '/LOGO',
          texto2: store.state.user.empresaActual.imagen2,
          fuente: 'Lucida Console',
          tamano: 8,
          color: 'black',
          separador: 10,
          alineacion: 'center',
        },
        {
          texto: store.state.user.empresaActual.razonSocial,
          fuente: 'Lucida Console',
          tamano: 8,
          color: 'black',
          separador: 40,
          alineacion: 'center',
        },
        {
          texto: store.state.user.empresaActual.documento,
          fuente: 'Lucida Console',
          tamano: 8,
          color: 'black',
          separador: 15,
          alineacion: 'center',
        },
        {
          texto: 'FECHA: ' + FuncionesGenerales.formatoFecha(datos.fechaCreacion, 1),
          fuente: 'Lucida Console',
          tamano: 8,
          color: 'black',
          separador: 25,
          alineacion: 'left',
        },
        {
          texto: 'HORA: ' + FuncionesGenerales.formatoFecha(datos.fechaCreacion, 2),
          fuente: 'Lucida Console',
          tamano: 8,
          color: 'black',
          separador: 15,
          alineacion: 'left',
        },
        {
          texto: 'NUMERO:' + datos.numeroControl,
          fuente: 'Lucida Console',
          tamano: 8,
          color: 'black',
          separador: 15,
          alineacion: 'left',
        },
        {
          texto: 'REF:' + datos.numeroDocumento,
          fuente: 'Lucida Console',
          tamano: 8,
          color: 'black',
          separador: 15,
          alineacion: 'left',
        },
        {
          texto: '/SEPARACION2',
          fuente: 'Lucida Console',
          tamano: 8,
          color: 'black',
          separador: 15,
          alineacion: 'left',
        },
        {
          texto: 'RIF/CI: ' + (datos.idCliente != 0 ? datos.cliente.documento : '123456789'),
          fuente: 'Lucida Console',
          tamano: 8,
          color: 'black',
          separador: 15,
          alineacion: 'left',
        },
        {
          texto:
            'CLIENTE: ' +
            (datos.idCliente != 0
              ? datos.cliente.nombre + ' ' + datos.cliente.apellido
              : 'Cliente General'),
          fuente: 'Lucida Console',
          tamano: 8,
          color: 'black',
          separador: 15,
          alineacion: 'left',
        },
        {
          texto: '/SEPARACION1',
          fuente: 'Lucida Console',
          tamano: 8,
          color: 'black',
          separador: 15,
          alineacion: 'left',
        },
      ]

      datos.detalleFactura.forEach(element => {
        if (element.numeroUnidades == 1) {
          datosImprimir.push({
            texto: element.productoSucursal.producto.nombre,
            texto2: FuncionesGenerales.formatoNumeric(element.total),
            fuente: 'Lucida Console',
            tamano: 8,
            color: 'black',
            separador: 15,
            alineacion: 'left',
          })
        } else {
          datosImprimir.push({
            texto: element.productoSucursal.producto.nombre,
            texto2: '',
            fuente: 'Lucida Console',
            tamano: 8,
            color: 'black',
            separador: 15,
            alineacion: 'left',
          })
          datosImprimir.push({
            texto: element.numeroUnidades + ' X' + (element.pvp + element.iva),
            texto2: FuncionesGenerales.formatoNumeric(element.total),
            fuente: 'Lucida Console',
            tamano: 8,
            color: 'black',
            separador: 15,
            alineacion: 'left',
          })
        }
      })

      datosImprimir.push({
        texto: '/SEPARACION1',
        fuente: 'Lucida Console',
        tamano: 8,
        color: 'black',
        separador: 15,
        alineacion: 'left',
      })

      datosImprimir.push({
        texto: 'SUB TOTAL',
        texto2: FuncionesGenerales.formatoNumeric(datos.montoSubTotal),
        fuente: 'Lucida Console',
        tamano: 8,
        color: 'black',
        separador: 15,
        alineacion: 'left',
      })
      datosImprimir.push({
        texto: 'IMPUESTO',
        texto2: FuncionesGenerales.formatoNumeric(datos.montoIva),
        fuente: 'Lucida Console',
        tamano: 8,
        color: 'black',
        separador: 15,
        alineacion: 'left',
      })
      datosImprimir.push({
        texto: 'TOTAL',
        texto2: FuncionesGenerales.formatoNumeric(datos.montoTotal),
        fuente: 'Lucida Console',
        tamano: 8,
        color: 'black',
        separador: 15,
        alineacion: 'left',
      })
      datosImprimir.push({
        texto: '/SEPARACION1',
        fuente: 'Lucida Console',
        tamano: 8,
        color: 'black',
        separador: 15,
        alineacion: 'left',
      })
      datosImprimir.push({
        texto: 'GRACIAS POR SU VISITA',
        fuente: 'Lucida Console',
        tamano: 8,
        color: 'black',
        separador: 15,
        alineacion: 'center',
      })

      ImprimirTermicaServices.Imprimir({ impresora: 'XP-58', printObjeto: datosImprimir })
        .then(response => {
          if (response.data.estatus) {
            store.commit('setAlert', {
              message: 'Se envio a la impresora',
              type: 'success',
            })
          } else {
            store.commit('setAlert', {
              message: 'Error Impresion' + response.data.mensaje,
              type: 'warning',
            })
          }
        })
        .catch(error => {
          store.commit('setAlert', {
            message: 'Error Impresion' + error,
            type: 'error',
          })
        })
    }

    const GetImprimir = item => {
      Imprimir(item)
    }
    const GetAnular = item => {
      context.emit('GetAnular', item)
    }
    const GetEditar = item => {
      context.emit('GetEditar', item)
    }
    const GetPdf = item => {
      context.emit('GetPdf', item)
    }
    const GetFinalizar = item => {
      context.emit('GetFinalizar', item)
    }

    return {
      datos,
      FuncionesGenerales,
      cerrarModal,
      cargar,
      icons: {
        mdiClose,
        mdiPrinter,
        mdiPencil,
        mdiCheck,
        mdiBlockHelper,
        mdiFilePdfBox,
        mdiPrinterPos
      },
      GetAnular,
      GetEditar,
      GetPdf,
      GetFinalizar,
      GetImprimir,
      cargando,
    }
  },
}
</script>